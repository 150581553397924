<template>
  <div
    class="content-section blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <banner-section location="shaniv_product_details-top"/>

    <section v-if="productData.id" class="main-content-part m-b-50">
      <div class="product-part">
        <img src="/new-assets/img/shaniv-logo.png" alt="" class="product-logo m-b-5" />
        <div class="product-content d-flex">
          <img
            :src="productData.image || '/new-assets/img/shaniv-logo.png'"
            alt=""
            class="product-img"
          />
          <div class="mx20">
            <h2 class="product-title color-red7">
              {{ productData[currentLang + '_name'] }}
            </h2>
            <!-- <p class="m-b-30">
              <span class="color-red1">short description - from our management</span>
            </p>-->
            <p class="m-b-10" v-if="productData[currentLang + '_description']">
              <b>{{ $t('MANAGE_SHANIV.DESCRIPTION') }}</b
              >: <span class="color-red1">{{ productData[currentLang + '_description'] }}</span>
            </p>
            <p class="m-b-10 fs16">
              <b>{{ $t('SASTECH.CONTENT') }}</b
              >: {{ productData.SPEC13 }}
            </p>
            <p class="m-b-10 fs16">
              <b>{{ $t('MANAGE_SHANIV.NUMBER_UNITS_PACKAGE') }}</b
              >: {{ productQtySteps }}
            </p>
            <!-- <p class="m-b-10 fs16">
              <b>{{ $t('MANAGE_SHANIV.NUMBER_UNITS_PER_SURFACE') }}</b
              >: {{ productData.FSHA_CARTONINLAYER }}
            </p> -->
            <p class="m-b-10 fs16">
              <b>{{ $t('MANAGE_SHANIV.SKU') }}</b
              >: {{productData.PARTNAME}}
            </p>
            <p class="fs16">
              <b>{{ $t('MANAGE_SHANIV.BARCODE') }}</b
              >: {{productData.BARCODE}}
            </p>
          </div>
        </div>
        <div class="m-product-content d-flex p-relative">
					<h2 class="product-title text-center color-red7 m-b-20">אנטי-ראסט ירוק</h2>
					<img src="/new-assets/icon/long-arrow-left-light.svg" alt="" class="m-arrow-icon pointer">
					<div class="d-flex m-b-20">
						<img
              :src="productData.image || '/new-assets/img/shaniv-logo.png'"
              alt=""
              class="product-img"
            />
						<!-- <p class="mx10">
              <span class="color-red1">short description - from our management</span>
            </p> -->
					</div>
          <div>
            <!-- <p class="m-b-10">
              <b>{{ $t('MANAGE_SHANIV.USER_MANUAL') }}</b
              >: <span class="color-red1">extended description (display if available) -from our management</span>
            </p> -->
            <p class="m-b-10 fs16">
              <b>{{ $t('SASTECH.CONTENT') }}</b
              >: {{ productData.SPEC13 }}
            </p>
            <p class="m-b-10 fs16">
              <b>{{ $t('MANAGE_SHANIV.NUMBER_UNITS_PACKAGE') }}</b
              >: {{ productQtySteps }}
            </p>
            <p class="m-b-10 fs16">
              <b>{{ $t('MANAGE_SHANIV.SKU') }}</b
              >: {{productData.PARTNAME}}
            </p>
            <p class="fs16">
              <b>{{ $t('MANAGE_SHANIV.BARCODE') }}</b
              >: {{productData.BARCODE}}
            </p>
          </div>
        </div>
      </div>
      <div class="cart-part m-t-40">
        <div class="cart-block p10">
          <p class="fs14 weight-100 m-b-5">
            {{ $t('MANAGE_SHANIV.PRICE_PER_UNIT') }}: {{ productDiscountVATPrice }} ₪
          </p>
          <p class="fs14 weight-100 m-b-5">
            {{ $t('SASTECH.PACKAGE_QUANTITY') }}: {{ productQtySteps }}
          </p>
          <p class="m-b-5">
            <b>{{ $t('SASTECH.PACKAGE_PRICE') }}: {{ productPackageVATPrice }} ₪</b>
          </p>
          <div class="d-flex align-items-center m-b-10">
            <p><b>{{ $t('GIFTCARD_PAYMENT_RESULT.QUANTITY') }}:</b> </p>
            <div class="cart-num mx10">
              <img
                src="/new-assets/icon/plus-circle-solid.svg"
                alt=""
                class="circle-btn plus-circle"
                @click="onIncreaseQuantity()"
              />
              <div class="product-num">
                <p>{{ productNum }}</p>
              </div>
              <!-- <input type="text" class="product-num" v-model=" getTempQuantity(item) " :disabled="disabled" /> -->
              <img
                src="/new-assets/icon/minus-circle-solid.svg"
                alt=""
                class="circle-btn minus-circle"
                @click="onDecreaseQuantity()"
              />
            </div>
          </div>
          <p class="color-blue3 fs18 m-b-5">
            {{ $t("MANAGE_SHANIV.AMOUNT_TO_ORDER")}}:  
            <i class="fs20">{{ amountToOrder }}</i> ₪
          </p>
          <button class="primary-btn" @click='addToCart(productData)'>
            <img
              src="/new-assets/icon/shopping-cart-light.svg"
              alt=""
              class="mx10"
            />
            <p>{{ $t('MANAGE_SHANIV.ADD_TO_BASKET') }}</p>
          </button>
          <!-- <p>
            {{ $t('MANAGE_SHANIV.RECOMMENDED_RETAIL_PRICE') }} ??? ₪
          </p> -->
        </div>
        <div class="m-t-20" v-if="product_package_list && product_package_list.data && product_package_list.data.length">
          <VueSlickCarousel
            :slidesToShow="1"
            :adaptiveHeight="true"
            :dots="true"
            class="related-package-carousel cus-slick-carousel"
            :class="sliderDirection=='rtl' ? 'rtl' : ''"
            :rtl="sliderDirection=='rtl' ? true : false"
          >
            <div v-for="(item, index) in product_package_list.data" :key="index" class="bg-white">
              <div class="package-carousel-header weight-700">
                {{ $t("MANAGE_SHANIV.PACKAGE_OFFER") }}: <span class="color-red5 mx10">{{ $t("MANAGE_SHANIV.SAVE") }} {{ item.DISCOUNT_PRICE }}₪</span>
              </div>
              <img :src="item.image" class="package-image" />
              <div class="package-action-block">
                <button class="primary-btn cart-btn" @click='addPackageToCart(item)'>
                  <img
                    src="/new-assets/icon/shopping-cart-light.svg"
                    alt=""
                    class="mx10"
                  />
                  <p>{{ $t('MANAGE_SHANIV.ADD_TO_CART_PROMOTION') }}</p>
                </button>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </section>
    <section v-if="false" class="weight-700 m-t-15 together-products">
      <p>{{ $t('MANAGE_SHANIV.PRODUCTS_PURCHASED_TOGETHER_OFTEN') }}</p>
      <div class="d-flex">
        <div class="d-flex align-items-center product-image_group w-50">
          <img src="/new-assets/img/אנטי-רטסט-ירוק.png" alt="" />
          <p class="plus">+</p>
          <img src="/new-assets/img/Component 14 – 1.png" alt="" />
          <p class="plus">+</p>
          <img src="/new-assets/img/Component 15 – 1.png" alt="" />
        </div>
        <div class="fs16 w-50">
          <div class="d-flex align-items-center m-b-10">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10 color-blue2"> אנטי-ראסט ירוק </label>
          </div>
          <div class="d-flex align-items-center m-b-10">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10 color-blue2">
              טאצ' תרסיס חיטוי והגנה 400 מ"ל
            </label>
          </div>
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
            <label class="px10 color-blue2">
              טאצ' אקונומיקה סמיכה מבושמת עם פקק ביטחון חסין פתיחה לילדים - 4
              ליטר
            </label>
          </div>
          <button class="primary-btn">
            <img
              src="/new-assets/icon/shopping-cart-light.svg"
              alt=""
              class="mx10"
            />
            <p>{{ $t('MANAGE_SHANIV.ADD_SELECTED_ITEMS_TO_CART') }}</p>
          </button>
        </div>
      </div>
    </section>
    <section v-if="false" class="m-t-15 m-b-30 sasa-category">
      <p class="weight-700 m-b-10">{{ $t('MANAGE_SHANIV.CUSTOMERS_ALSO_SAW') }}</p>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel"
      >
        <div v-for="index in 10" :key="index">
          <div class="sastech-box" :class="currentLang=='en' ? 'content-ltr' : 'content-rtl'">
            <div class="content">
              <div class="slider-box__center df">
                <div class="slider-box__center-img">
                  <img
                    src="/new-assets/img/shaniv-logo.png"
                    alt=""
                  />
                </div>
                <div class="sastech-box__center-content">
                  <p>טאצ' נוזל כלים</p>
                  <p>בניחוח תפוח</p>
                  <!-- <p>{{ item[currentLang + '_name'] }}</p>
                  <p class="fs18">{{ item[currentLang + '_description'] }}</p> -->
                  <p>18%</p>
                </div>
              </div>
              <div class="slider-box__footer sbf2 df bg-red5">
                <div class="slider-footer__add-btn">
                  <div
                    class="slider-footer__add-img cursor-pointer df"
                  >
                    <img src="/new-assets/icon/plus-circle-solid.svg" alt="" />
                  </div>
                  <div class="footer__addbtn-p df-c">
                    <p>{{ 0 }}</p>
                  </div>
                  <div
                    class="footer__addbtn-img cursor-pointer df"
                  >
                    <img src="/new-assets/icon/minus-circle-solid.svg" alt="" />
                  </div>
                </div>
                <!-- <div class="slider-footer__box1">
                  
                </div> -->
                <div class="">
                  <div class="footer__box2-title df">
                    <!-- <p style="color: yellow" v-if="item.plist != null">
                      ₪{{ item.plist }}
                    </p> -->
                    <img src="/new-assets/icon/Group 2240.svg" alt="" />
                    <p>במבצע!</p>
                    <img src="/new-assets/icon/₪37.svg" alt="" />
                  </div>
                  <div class="footer__box2-txt">
                    <p>מחיר מומלץ לצרכן59 ₪</p>
                  </div>
                  <div
                    class="footer__box2-btn df align-items-center cursor-pointer"
                  >
                    <img
                      src="/new-assets/icon/shopping-cart-light.svg"
                      class="mx10"
                      alt=""
                    />
                    <p class="fs18">הוסף לסל</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </section>
    <section class="m-footer">
			<div>
        <p><b>₪ {{ amountToOrder }}</b></p>
        <p>{{ $t('SASTECH.BEFORE_DISCOUNT') }}</p>
      </div>
      <button @click='addToCart(productData)'
        class="primary-btn bg-blue add-basket_btn"
      >
        <img
          src="/new-assets/icon/shopping-cart-light.svg"
          alt=""
          class="mx5"
        />
        <p>{{ $t('MANAGE_SHANIV.ADD_TO_BASKET') }}</p>
      </button>
    </section>
    <!-- ------------More detail modal-------------- -->
    <CartModal v-on:closeModal="closeCartModal()" :cartModalData="cartModalData" :isPackageModal="isPackageModal" :key="domReload"></CartModal>
		<loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import  CartModal from "../../components/CartModal";
import BannerSection from "../../components/BannerSection";
function calculateItemPerSlide() {
  if (window.innerWidth < 650) {
    return 1;
  }

  if (window.innerWidth < 1080) {
    return 2;
  }

  return 4;
}
export default {
  name: 'SastechProduct',
  components: {
    BannerSection,
    CartModal,
  },
  data() {
    return {
      nextLabel: "<img class src='/assets/img/chevron.svg' />",
      prevLabel: "<img class src='/assets/img/chevron.svg' />",
      itemPerSlide: calculateItemPerSlide(),
      productNum: 1,
      cartModalData: {},
      isPackageModal: false,
      domReload: true
    };
  },
	computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      product_detail: (state) => state.shaniv_management.product_detail,
      package_sale: (state) => state.shaniv_management.package_sale,
      productData: (state) => state.productShaniv.productData,
      isManager: (state) => state.shaniv_management.isManager,
      product_package_list: (state) => state.shaniv_management.product_package_list
    }),
    ...mapGetters('cartSystem', {
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalUniqueCount: 'cartTotalUniqueCount'
    }),
    productId() {
      return this.$route.params.id;
    },
    productQtySteps() {
      return this.productData && this.productData.qtySteps ? this.productData.qtySteps : 1;
    },
    productDiscountVATPrice() {
      return this.productData && this.productData.DISCOUNT_VATPRICE ? this.productData.DISCOUNT_VATPRICE : 0;
    },
    productPackageVATPrice() {
      return Math.round((this.productDiscountVATPrice * this.productQtySteps + Number.EPSILON) * 100) / 100;
    },
    amountToOrder() {
      return Math.round(this.productPackageVATPrice * this.productNum * 100) / 100;
    }
  },
  methods: {
    ...mapActions('cartSystem', {
      addProductsToCart: 'addProductsToCart',
      initCart: 'initCart',
    }),
    ...mapActions('shaniv_management', {
      getProductDetail: 'getProductDetail',
      // getPackageIDs: 'getPackageIDs',
      getPackageListById: 'getPackageListById'
      // getProductData: 'getProductData'
    }),
    ...mapActions('productShaniv', {
      getProductData: 'getProductData'
    }),
    onResize(event) {
      this.itemPerSlide = calculateItemPerSlide();
    },
    loadProductData() {
      let params = {
        active: 1,
        user_prices: 1,
        id: this.productId
      };

      this.getProductData(params);
    },
    createOpenModal(product, quantity) {
      this.cartModalData = product;
      this.cartModalData.added_quantity = quantity;
      this.cartModalData.added_total_price = this.calculateProductQuantityPrice({   
        DISCOUNT_VATPRICE: this.cartModalData.DISCOUNT_VATPRICE,
        quantity: this.cartModalData.added_quantity,
        qtySteps: this.cartModalData.qtySteps
      });
      this.cartModalData.package_price = this.getProductPackagePrice(product);
      this.cartModalData.package_discount_price = this.getProductPackageDiscountPrice(product); 
      this.cartModalData.total_price = this.cartTotalDiscountVATPrice; 
      this.cartModalData.total_unique_count = this.cartTotalUniqueCount;

      this.cartModalData = JSON.parse(JSON.stringify(this.cartModalData));

      this.domReload = +new Date();
      setTimeout(() => {
        this.openCartModal();
      }, 100);
    },
    addToCart(product) {
      let quantity = this.productNum;
      if (quantity <= 0) {
        return;
      }
      this.addProductsToCart({
        product,
        quantity,
        company: product.company,
        type: 'products',
      });
      
      this.isPackageModal = false;
      this.createOpenModal(product, this.productNum);
      // this.productNum = 1;
    },
    addPackageToCart(product) {
      let quantity = 1;
      this.addProductsToCart({
        product,
        quantity,
        company: product.company,
        type: 'packages',
      });

      this.isPackageModal = true;
      this.createOpenModal(product, quantity);
    },
    onIncreaseQuantity() {
      this.productNum += 1;
    },
    onDecreaseQuantity() {
      if (this.productNum > 1) {
        this.productNum -= 1;
      }
    },
    /* <!-- ------------cart popup-------------- --> */
    openCartModal() {
      this.$modal.show("cartModal");
    },
    closeCartModal() {
      this.$modal.hide("cartModal");
    },
    calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackageDiscountPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackagePrice({ id, qtySteps, DISCOUNT_VATPRICE, VATPRICE }) {
      VATPRICE = VATPRICE >= DISCOUNT_VATPRICE ? VATPRICE : DISCOUNT_VATPRICE;
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.initCart();
    this.loadProductData();
    this.getPackageListById({id: this.productId});
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>
<style lang="scss">
.related-package-carousel.rtl .cart-btn {
  direction: rtl !important;
}
.related-package-carousel {
  .slick-list {
    // height: 100%;
    .package-image {
      width: 100%;
    }
  }
  .package-carousel-header {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #707070;
  }
  .package-action-block {
    padding: 5px 15px;
    .cart-btn {
      height: 40px;
      border-radius: 5px;
    }
  }
}
</style>
<style lang="scss" scoped>

.drop-down-list {
  width: 200px;
}
.v-menu__content{
  top: 0px !important;
  left: 0px !important;
  height: fit-content !important;
  width: fit-content !important;
  position: relative !important;
}
.item_in_list{
  cursor: pointer !important;
}
.item_in_list:hover {
  background-color: gray !important;
}


.cus-slider .VueCarousel-navigation-button.VueCarousel-navigation-next img {
  transform: rotate(180deg);
}
// .content-section {
//   @media screen and (min-width: 1365px) {
//     width: calc(100% - 250px);
//   }
// }
.content-section {
  margin-top: 20px;
  padding: 0 50px;
	width: 100%;
  @media screen and (max-width: 930px) { 
		padding: 0 20px;
	}
  .plus {
    font-size: 50px;
  }
  .product-image_group {
    justify-content: space-around;
    img {
      width: 80px;
    }
  }
  .main-content-part {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    padding-bottom: 20px;
		@media screen and (max-width: 930px) { 
			margin-top: 0;
		}
    // border-bottom: 1px solid #898989;
    .product-part {
      padding-left: 50px;
			@media screen and (max-width: 930px) { 
				padding-left: 0;
			}
      .product-logo {
        height: 32px;
				@media screen and (max-width: 930px) { 
					display: none;
				}
      }
      .product-content {
        align-items: flex-start;
				@media screen and (max-width: 930px) { 
					display: none;
				}
        .product-img {
          object-fit: contain;
          width: 120px;
          min-width: 120px;
          margin: 0 10px;
        }
        .product-title {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
    }

    .cart-part {
      max-width: 450px;
      width: 100%;
      padding: 0 50px;
      .cart-block {
        background-color: #d5d5d5;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        height: -moz-fit-content;
        height: fit-content;
        .price {
          margin-left: 20px;
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        .quantity-dropdown {
          width: 82px;
          height: 36px;
          .vs__selected {
            height: 100%;
            top: 0;
          }
          .vs__dropdown-menu {
            min-width: 70px;
            max-width: 70px;
          }
          .vs__search {
            padding: 0;
          }
        }
        .primary-btn {
          height: 40px;
          border-radius: 5px;
					@media screen and (max-width: 930px) { 
						margin: 0 auto;
					}
        }
        .cart-header {
          padding: 10px;
          border-bottom: 1px solid #898989;
        }
      }
			@media screen and (max-width: 930px) { 
				max-width: unset;
			}
    }
		@media screen and (max-width: 930px) { 
			flex-wrap: wrap;
		}
  }
  .together-products {
    border-bottom: 1px solid #898989;
    padding-bottom: 30px;
    .product-image_group {
      justify-content: flex-start;
      .plus {
        margin: 0 20px;
      }
    }
    .primary-btn {
      height: 40px;
      border-radius: 5px;
      width: auto;
      padding: 0 30px;
      margin-top: 30px;
    }
  }
}

.m-product-content {
	display: none;
}

.m-footer {
	display: none;
	.add-basket_btn {
		border-radius: 20px;
		height: 38px;
		width: auto;
		padding: 0 20px;
		font-size: 16px;
	}
}

@media screen and (max-width: 930px) { 
	.main-content-part {
		margin-bottom: 90px;
	}
	.m-product-content {
		display: block;
		.product-img {
			width: 70px;
			object-fit: contain;
		}
		.m-arrow-icon {
			position: absolute;
			right: 0;
			top: -13px;
		}
	}
	.m-footer {
		display: flex;
	}
}

  .v-application--wrap{
    min-height: 25px !important;
  }
  .text-center {
    width:fit-content;
    height:25px;
  }
  .v-btn__content {
    font-size: 20px;
  }

  .cart-num {
    position: relative;
    width: 82px;
    display: flex;
    .circle-btn {
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }
    .plus-circle {
      right: 0px;
    }
    .minus-circle {
      left: 0px;
    }
    .product-num {
      background-color: #fff;
      width: 60px;
      text-align: center;
      margin: 0 12px;
      height: 24px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>