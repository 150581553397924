<template>
  <modal
    :width="550"
    :adaptive="true"
    class="cartModal CEModal modal-scroll-bar"
    name="cartModal"
  >
    <div class="modal-header">
      <img
        src="/assets/img/icons/check-circle-light.svg"
        class="check-icon"
        alt
      />
      <h4>{{ $t('SASTECH.ADDED_TO_CART') }}</h4>
      <span class="fs16"
        >( {{ cartModalData.total_unique_count }}
        {{ $t('SASTECH.ITEMS') }} )</span
      >
      <button class="button modal-close_btn" @click="$emit('closeModal')">
        <img src="/assets/img/icons/times-light-orange.svg" alt />
      </button>
    </div>
    <div class="modal-content d-flex">
      <img
        :src="cartModalData.image || '/new-assets/img/shaniv-logo.png'"
        class="product-image"
        alt=""
      />
      <div class="mx30">
        <h4 class="m-b-10" v-if="isPackageModal">{{ cartModalData.name }}</h4>
        <h4 class="m-b-10" v-else>{{ cartModalData[currentLang + '_name'] }}</h4>
        <p class="m-b-10 fs16" v-if="!isPackageModal">
          {{ cartModalData[currentLang + '_description'] }}
        </p>
        <div class="d-flex m-b-15 fs16">
          <p v-if="cartModalData.SPEC13" class="m-r-10">
            <b>{{ $t('SASTECH.CONTENT') }}: </b>
            <span>{{ cartModalData.SPEC13 }}</span>
          </p>
          <p class="">
            <b>{{ $t('GIFTCARD_PAYMENT_RESULT.QUANTITY') }}: </b>
            <span> {{ cartModalData.added_quantity }}</span>
          </p>
        </div>
        <div class="d-flex fs20 weight-700">
          <p>
            <span>{{ cartModalData.package_discount_price }} </span>
            <span class="fs16">₪</span>
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer m-b-10">
      <p class="weight-700 m-t-10">
        <span class="fs16">{{ $t('SASTECH.TOTAL_ADDED_TXT') }}: </span>
        <span class="fs20">{{ cartModalData.added_total_price }} </span>
        <span>₪</span>
      </p>
      <div class="text-center">
        <p class="weight-700 color-orange">
          <span class="fs14">{{ $t('SASTECH.TOTAL_IN_CART') }}: </span>
          <span>{{ cartModalData.total_price }} </span>
          <span class="fs14">₪</span>
        </p>
        <router-link to="/cart" class="text-decoration-none">
          <button class="primary-btn to-office_btn" @click="$emit('closeModal')">
            {{ $t('SASTECH.TO_BOX_OFFICE') }}
          </button>
        </router-link>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'CartModal',
  props: {
    cartModalData: Object,
    isPackageModal: Boolean
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss">
.cartModal .modal-content .product-image {
  max-width: 100px;
  max-height: 150px;
  object-fit: contain;
}
.cartModal .text-center {
  height: auto !important;
}
</style>